import React from "react";
import "./Footer.css";
function Footer(){
    return(
        
        <div class="foot">
            <footer>
                <p>Copyright &copy; 2022</p>
                <p><a href="mailto:tanejamanan141@gmail.com">tanejamanan141@gmail.com</a></p>
            </footer>
        </div>
        
    )
}
export default Footer